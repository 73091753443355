import * as React from "react";
import { WavePlayer } from "./aktueller-podcast";
import { uiRegistry } from "../utils/ui-registry";
import { Footer } from "./footer";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import { MaxWidth, maxMedia } from "../utils/style";

export const Layout = React.forwardRef(
  ({ children, ...props }: any, ref: any) => {
    React.useEffect(() => {
      const isIE11 = eval(
        "!!window.MSInputMethodContext && !!document.documentMode"
      );
      if (isIE11) return;
      // window.dispatchEvent(new Event("resize"));
    });

    const data = useStaticQuery(graphql`
      {
        file(relativePath: { eq: "cmc.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `);

    return (
      <div {...props} ref={ref}>
        <WavePlayer
          playRef={_play => {
            uiRegistry.play = _play;
          }}
        />

        {children}

        <CMC>
          <a href="https://www.cmc-global.org/" target="_blank">
            <GatsbyImage fluid={data.file.childImageSharp.fluid} />
          </a>
        </CMC>

        <Footer />
      </div>
    );
  }
);

const CMC = styled.div`
  position: fixed;
  right: 0;
  bottom: 3rem;
  width: 100px;
  z-index: 1;

  ${maxMedia.tablet`
    position: absolute;
  `}

  ${maxMedia.phone`
    width: 100px;
    bottom: auto;
    top: 40vh;
  `}
`;
