export const defaultPodcastUrls = [
  "apple+https://podcasts.apple.com/us/podcast/verhalten-gestalten-der-podcast/id1465103960?ign-mpt=uo%3D4",
  "spotify+https://open.spotify.com/show/1IRSQmbGbt8IxY4w9fhrFP"
];

export const picFilter = "sepia(100%) brightness(60%) hue-rotate(301.3deg)";
export const picFilter1 = "brightness(60%)";

export const SocialLinks = {
  youtube: "#",
  linkedIn: "https://de.linkedin.com/in/winfriedneun",
  xing: "https://www.xing.com/profile/Winfried_Neun"
};

export const ROOT = "https://verhalten-gestalten.de/"
