import * as React from "react";
import { VanillaTilt } from "../lib/vanilla-tils";
import Img, { GatsbyImageProps } from "gatsby-image";

// window.VanillaTilt = VanillaTilt;
export const TiltImage = (props: GatsbyImageProps) => {
  const node = React.useRef<any>();

  return (
    <Img
      {...props}
      style={
        {
          boxShadow: "0 10px 25px -10px rgba(0,0,0, 0.8)",
          ...props.style
        } as React.CSSProperties
      }
      onLoad={() => {
        VanillaTilt.init(node.current.imageRef.current.parentNode.parentNode, {
          max: 10,
          reset: false,
          startX: 10,
          startY: 10,
          glare: true,
          reverse: true,
          "max-glare": 0.7
        });
      }}
      ref={node}
    />
  );
};
