import styled, { css } from "styled-components";
import * as React from "react";

export const EasyGrid = styled.div<{ vert?: boolean }>`
  display: flex;

  ${p =>
    p.vert
      ? css`
          flex-direction: column;

          > *:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        `
      : css`
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: -0.5rem;

          > * {
            margin-top: 0.5rem;
          }

          > *:not(:last-child) {
            margin-right: 0.5rem;
          }
        `}
`;
