import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import { theme } from "../utils/style";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

let options = {
  renderNode: {
    "embedded-asset-block": node => {
      if (node.data.target.fields) {
        return <img src={node.data.target.fields.file["en-US"].url} />;
      }
      return null;
    }
  }
};

const RTF = (props => {
  return <RTFDiv>{documentToReactComponents(props.content, options)}</RTFDiv>;
}) as any;

export default RTF;

export const RTFDiv = styled.div`
  a {
    color: ${theme.primaryColor};
  }

  > * {
    white-space: pre-wrap;
  }
`;
