import { graphql, useStaticQuery } from "gatsby";
import Plyr from "plyr";
import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import styled, { css } from "styled-components";
import { defaultPodcastUrls } from "../utils/const";
import { centerFlex, maxMedia, MaxWidth } from "../utils/style";
import { uiRegistry } from "../utils/ui-registry";
import RTF from "./rtf";
import { EasyGrid } from "./styles/EasyGrid";
import { LinkButton } from "./styles/LinkButton";
import { TiltImage } from "./tilt-image";
interface Props {}

const Div = styled.div`
  margin: 3rem 0;
`;
const Content = styled.div`
  display: flex;
  align-items: center;

  ${maxMedia.tablet`
    flex-direction: column;
  `}

  > * {
    margin: 0 1rem;

    ${maxMedia.tablet`
    margin: 1rem 0;
  `}
  }

  .right {
    flex-grow: 1;
  }

  .left {
    ${centerFlex}
  }
`;

export const AktuellerPodcast = (props: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPodcast(
        limit: 1
        sort: { fields: [published], order: DESC }
      ) {
        nodes {
          id
          title
          description {
            id
            json
          }
          image {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mp3 {
            id
            file {
              url
            }
          }
          podcastUrls
        }
      }
    }
  `);

  const x = data.allContentfulPodcast.nodes[0];

  if (!x) {
    return null;
  }

  return (
    <MaxWidth id="aktuell">
      <Div>
        <h2 style={{ textAlign: "center", marginBottom: "3rem" }}>
          Aktueller Podcast
        </h2>
        <Content>
          {x.image && (
            <div className="left" style={{ flexDirection: "column" }}>
              <TiltImage style={{ width: 300 }} fluid={x.image.fluid} />
            </div>
          )}
          <div className="right">
            <h4>{x.title}</h4>
            <RTF content={x.description.json as any} />

            <EasyGrid>
              <LinkButton
                onClick={() => {
                  uiRegistry.play(x.mp3.file.url);
                }}
              >
                Abspielen
              </LinkButton>
              <LinkButton outline href={x.mp3.file.url} download>
                Herunterladen
              </LinkButton>
              {((x.podcastUrls || defaultPodcastUrls) as string[]).map(x => {
                const [head, ...tail] = x.split("+");
                const joinedTail = tail.join("+");
                return (
                  <LinkButton outline href={joinedTail} target="_blank">
                    {_.capitalize(head)} Podcast
                  </LinkButton>
                );
              })}
            </EasyGrid>

            {/* <ReactAudioPlayer src={x.mp3.file.url} autoPlay controls /> */}
          </div>
        </Content>
      </Div>
    </MaxWidth>
  );
};

const WavePlayerDiv = styled.div<{ active?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 10;

  ${maxMedia.tablet`
    width: 100%;
  `}

  ${p =>
    p.active
      ? css`
          transform: translateX(-50%) translateY(0%);
        `
      : css`
          transform: translateX(-50%) translateY(100%);
        `};

  transition: transform 0.2s ease-in-out;

  border-radius: 1rem 1rem 0 0;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.8);
  background: white;
  display: inline-flex;
  align-items: center;

  .plyr__controls {
    display: inline-flex;
    border-radius: 1rem 1rem 0 0;
  }

  .closer {
    margin-right: 1rem;
    font-size: 1.5rem;
    display: flex;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
`;
export const WavePlayer = ({
  ...props
}: {
  playRef?: (play: (newAudioSource: string) => void) => void;
}) => {
  const [audioSrc, setAudioSrc] = useState(undefined);
  const audio = useRef<HTMLAudioElement>();
  useEffect(() => {
    const isIE11 = eval(
      "!!window.MSInputMethodContext && !!document.documentMode"
    );
    if (isIE11) return;

    const player = new Plyr("#player");
    return () => {};
  }, []);

  useEffect(() => {
    const playRef = newAudioSource => {
      setAudioSrc(newAudioSource);
      audio.current.src = newAudioSource;
      audio.current.play();
    };
    props.playRef(playRef);
  }, []);

  return (
    <WavePlayerDiv active={!!audioSrc}>
      <audio ref={audio} id="player" controls>
        <source src={audioSrc} type="audio/mp3" />
      </audio>
      <span
        className="closer"
        onClick={() => {
          audio.current.pause();
          setAudioSrc(undefined);
        }}
      >
        <MdClose />
      </span>
    </WavePlayerDiv>
  );
};
