import { Link } from "gatsby";
import React, { useLayoutEffect, useState } from "react";
import { MdMenu, MdClose } from "react-icons/md";
import styled from "styled-components";
import {
  fillAbs,
  MaxWidth,
  maxMedia,
  TABLET_BR,
  minMedia
} from "../utils/style";
import { useWindowSize } from "../utils/window-size";
import { useSpring, animated } from "react-spring";

interface Props {
  children: any;
  small?: boolean;
  backgroundImg?: string;
}

const Div = styled.div`
  /* min-height: 80vh; */
  position: relative;

  ${MaxWidth} .inner {
    height: ${p => (p.small ? "60vh" : "80vh")};
    min-height: 600px;

    overflow: ${p => (p.expanded ? "visible" : "hidden")};

    max-height: 800px;
  }

  .header-bg {
    ${fillAbs};
    z-index: 0;
    background-image: url(${require("../images/trapez-bg.svg")});
    background-repeat: no-repeat;
    background-size: cover;

    ${maxMedia.tablet`
      display: none;
    `}
  }

  .header-logo {
    position: absolute;
    z-index: 20;
    right: 2rem;
    top: 2rem;

    ${maxMedia.tablet`
      max-width: 225px;
      right: 1rem;
      top: 1rem;
    `}
  }

  .menu {
    padding: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .menu-item {
      opacity: 0.8;
      display: block;
      position: relative;
      padding-right: 1rem;

      &:hover {
        opacity: 1;
      }
    }

    .menu-item:not(:last-child) {
      margin-bottom: 1rem;
    }

    .menu-item ul {
      position: absolute;
      opacity: 0;
      z-index: 1;
      border-radius: 0.5rem;
      transform: translateX(-1rem);
      transform-origin: center left;
      transition: all 0.2s linear;
      transition-delay: 0.2s;
      margin-left: 0;
      top: -1rem;
      left: 100%;
      background: rgba(0, 0, 0, 0.7);
      padding: 0 1rem;
      box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
      pointer-events: none;
    }

    .menu-item:hover ul {
      pointer-events: all;
      transform: translateX(0);
      opacity: 1;
    }

    .menu-item ul li {
      white-space: nowrap;
      list-style: none;
      margin: 1rem 0;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  .mobile-menu {
    ${fillAbs}
    height: 100vh;
    z-index: 10;

    > .bg {
      top: 0;
      left: 0;
      z-index: 0;
      /* background: red; */
      ${fillAbs}
    }

    > .mobile-menu-content {
      position: relative;
      z-index: 2;
      color: white;
      padding: 3rem;
      display: flex;
      flex-direction: column;
      max-height: 100vh;
      overflow-y: auto;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      > .inner-item {
        /* font-size: 0.8rem; */
        font-weight: 100;
        margin-left: 1rem;
      }
    }

    > .icon {
      font-size: 1.5rem;
      color: white;
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 3;
      cursor: pointer;
    }
  }

  .wn {
    position: absolute;
    margin: 0;
    bottom: 0;
    left: 7%;
    height: 75%;
    pointer-events: none;
  }

  .tagline {
    position: absolute;
    top: 200px;
    left: 50%;
    margin-right: 6rem;
  }

  .links {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    ${maxMedia.tablet`
      display: none;
    `}
  }

  .circle {
    position: absolute !important;
    ${minMedia.tablet`
      margin: 0%;
      top: 50%;
      transform: translateY(-37%);
      background-size: cover;
      border-radius: 50%;
      left: 21%;
      width: 400px;
      height: 400px;

      @media (max-width: 1150px) {
        top: 60%;

        width: 280px;
        height: 280px;
      }

      box-shadow: 0 10px 25px -10px rgba(0, 0, 0, 0.8);
      // filter: grayscale(100%);
    `}

    ${maxMedia.tablet`
      ${fillAbs};
      z-index: 0;
      background-size: cover;
      background-position: center top;
      filter: grayscale(100%);
    `}
  }

  .circle-text {
    ${minMedia.tablet`
    position: absolute;
    top: 50%;
    left: 58%;
    `}

    @media (max-width: 1150px) {
      top: 60%;
    }

    ${maxMedia.tablet`
    position: absolute;
    left: 0;
    padding: 3rem 1rem 1rem 1rem;
    bottom: 0;
    width: 100%;
    z-index: 1;
    color: white;
    background: linear-gradient(#00000000, #000000ff)
    `}
  }

  .std-text {
    position: absolute;
    top: 50%;
    left: 28%;
  }
`;

const menuStructure = [
  {
    title: "Podcast",
    to: "/#aktuell",
    children: [
      { title: "Aktueller Podcast", to: "/#aktuell" },
      { title: "Podcast Archiv", to: "/podcast/archiv" }
    ]
  },
  {
    title: "Meine Kompetenzen",
    to: "/#meine-kompetenzen",
    children: [
      { title: "Digitalisierung", to: "/meine-kompetenzen/digitalisierung" },
      { title: "Führung", to: "/meine-kompetenzen/fuehrung" },
      { title: "Innovation", to: "/meine-kompetenzen/innovation" },
      { title: "Resilienz", to: "/meine-kompetenzen/resilienz" }
    ]
  },
  {
    title: "Meine Leidenschaften",
    to: "/meine-leidenschaften",
    children: [
      { title: "Essen & Trinken", to: "/meine-leidenschaften/#essen+trinken" },
      { title: "Meer & Berge", to: "/meine-leidenschaften/#meer+berge" },
      { title: "Heimat & Ferne", to: "/meine-leidenschaften/#heimat+ferne" }
    ]
  },
  {
    title: "Blog",
    to: "/blog"
  },
  {
    title: "Über Winfried Neun",
    to: "/wn",
    children: [
      { title: "Vita", to: "/wn#vita" },
      { title: "Presse", to: "/wn#presse" }
    ]
  },
  {
    title: "Kontakt",
    to: "/#kontakt"
  }
];

export const Header = (props: Props) => {
  let size = useWindowSize();
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);

  const innerStyleProps = props.backgroundImg ? {
    background: `url("${props.backgroundImg}")`,
    backgroundSize: "cover",
    backgroundPosition: "bottom center"
  } : {}

  return (
    <Div {...props} expanded={mobileMenuExpanded}>
      <div className="header-bg" />
      <MaxWidth greyGradient style={innerStyleProps}>
        <Link to="/">
          <img src={require("../images/Logo.png")} className="header-logo" />
        </Link>
        {(size.width > TABLET_BR) ? (
          <div className="menu">
            {menuStructure.map(x => (
              <Link key={x.to} to={x.to} className="menu-item">
                › {x.title}
                {x.children && (
                  <ul>
                    {x.children.map(y => (
                      <li key={y.to}>
                        <Link to={y.to}>› {y.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </Link>
            ))}
          </div>
        ) : (
            <div className="mobile-menu">
              <div
                className="icon"
                onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)}
              >
                {mobileMenuExpanded ? <MdClose /> : <MdMenu />}
              </div>

              {mobileMenuExpanded && (
                <div className="mobile-menu-content">
                  {menuStructure.map(x => (
                    <>
                      <Link
                        key={x.to}
                        to={x.to}
                        className="menu-item"
                        onClick={() => setMobileMenuExpanded(false)}
                      >
                        › {x.title}
                      </Link>

                      {(x.children || []).map(y => (
                        <Link
                          className="inner-item"
                          to={y.to}
                          onClick={() => setMobileMenuExpanded(false)}
                        >
                          › {y.title}
                        </Link>
                      ))}
                    </>
                  ))}
                </div>
              )}

              {size.width !== undefined && (
                <MobileMenuBg expanded={mobileMenuExpanded} />
              )}
            </div>
          )}

        {size.width !== undefined && props.children}
      </MaxWidth>
    </Div>
  );
};

const MobileMenuBg = ({ expanded }: { expanded?: boolean }) => {
  let size = useWindowSize();

  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    if (expanded) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    }
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, [expanded]); // Empty array ensures effect is only run on mount and unmount

  const w = 100;
  const h = 180;

  const alpha = Math.atan(w / h);
  const H = size.height + size.width / Math.tan(alpha) - 100;
  const W = Math.tan(alpha) * H;

  const finalW = expanded ? W : w;
  const finalH = expanded ? H : h;

  const props3 = useSpring({
    wh: [finalW, finalH],
    config: { mass: 10, tension: 500, friction: 80 }
  });

  return (
    <svg
      className="bg"
      preserveAspectRatio="none slice"
      width={`${size.width}px`}
      // height={`${size.height}px`}
      viewBox={`0 0 ${size.width} ${size.height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: "translateZ(0)",
        willChange: "transform"
      }}
    >
      <defs>
        <radialGradient
          cx="100%"
          cy="0%"
          fx="100%"
          fy="0%"
          r="100%"
          gradientTransform="translate(1.000000,0.000000),scale(1.000000,0.534091),rotate(180.000000),scale(1.000000,1.704371),translate(-1.000000,-0.000000)"
          id="radialGradient-1"
        >
          <stop stop-color="#C20B21" offset="0%" />
          <stop stop-color="#5F0000" offset="100%" />
        </radialGradient>
      </defs>
      <g
        id="Mobile"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g fill="url(#radialGradient-1)" id="Path-3">
          <animated.polygon
            points={props3.wh.interpolate((w, h) => `${w} 0 0 ${h} 0 0`)}
          />
        </g>
      </g>
    </svg>
  );
};
