/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { IconButton, LinkButton, Button } from "./styles/LinkButton";

const useCookieAccpeted = () => {
  const [cookieAccepted, setCookieAccepted] = React.useState(undefined);
  useEffect(() => {
    const cookieAccepted = !!localStorage.getItem("cookieAccepted");
    setCookieAccepted(cookieAccepted);
  }, []);
  return cookieAccepted;
};

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const cookieAccepted = useCookieAccpeted();

  const metaDescription = description || site.siteMetadata.description;

  if (cookieAccepted === undefined) {
    return null;
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title || site.siteMetadata.title}
        titleTemplate={title ? `%s | ${site.siteMetadata.title}` : undefined}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
              : [],
          )
          .concat(meta)}
      >
        {/* CSP, only allow local css, img and fonts */}
        {!cookieAccepted && (
          <meta
            http-equiv="Content-Security-Policy"
            content="default-src 'self'; style-src 'self' 'unsafe-inline'; img-src 'self' data:; font-src 'self' data:; script-src 'self' 'unsafe-inline' 'unsafe-eval';"
          />
        )}

        {cookieAccepted && <script async src="https://www.googletagmanager.com/gtag/js?id=UA-83366657-8&aip=1" />}
      </Helmet>

      <CookieAccpet />
    </>
  );
}

const CookieAccpetDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
  }
`;

const CookieAccpet = () => {
  const cookieAccepted = useTracking();

  if (cookieAccepted || cookieAccepted === undefined) {
    return null;
  }

  const handleAccept = () => {
    localStorage.setItem("cookieAccepted", true);
    window.location.reload();
  };

  return (
    <CookieAccpetDiv>
      <div className="inner">
        <h3>Wir bitten um Ihre Zustimmung</h3>
        <p>
          Wir verwenden Cookies und andere Technologien auf unserer Website. Manche sind essenziell, andere helfen uns
          diese Website zu verbessern und den Erfolg unserer Kampagnen zu messen. Weitere Informationen dazu finden Sie
          in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
        </p>
        <Button onClick={handleAccept}>Alle akzeptieren</Button>
      </div>
    </CookieAccpetDiv>
  );
};

const useTracking = (() => {
  const cookieAccepted = useCookieAccpeted();

  useEffect(() => {
  if (!cookieAccepted) {
    return;
  }

  if (typeof window === "object") {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-83366657-8", { anonymize_ip: true, anonymizeIp: true });
  }
  }, [cookieAccepted])

  return cookieAccepted
});

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
  title: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO;
