import React from "react";
import { Link } from "gatsby";
import { MaxWidth, theme } from "../utils/style";
import styled from "styled-components";
import { EasyGrid } from "./styles/EasyGrid";
import { IconButton } from "./styles/LinkButton";
import { FaYoutube, FaLinkedin, FaXing } from "react-icons/fa";
import { SocialLinks } from "../utils/const";

interface Props {}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  > .impressum-datenschutz {
    a {
      text-decoration: none;
      color: ${theme.primaryColor};
      font-size: 1rem;
    }
  }

  > .copyright {
    font-size: 0.75rem;
  }
`;

export const Footer = (props: Props) => {
  return (
    <MaxWidth greyGradient>
      <Div>
        <div className="social-media-icons">
          <EasyGrid>
            {/* <IconButton outline href={SocialLinks.youtube} color="#D7143A">
              <FaYoutube />
            </IconButton> */}
            <IconButton outline href={SocialLinks.linkedIn} color="#0077B7">
              <FaLinkedin />
            </IconButton>
            <IconButton outline href={SocialLinks.xing} color="#00605E">
              <FaXing />
            </IconButton>
          </EasyGrid>
        </div>
        <div className="impressum-datenschutz">
          <EasyGrid>
            <Link to="/impressum">Impressum</Link>
            <Link to="/datenschutz">Datenschutz</Link>
          </EasyGrid>
        </div>
        <div className="copyright">
          Copyright 2019 © - Neun Consulting Group
        </div>
      </Div>
    </MaxWidth>
  );
};
