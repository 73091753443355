import React from "react";
import styled from "styled-components";
import {
  maxMedia,
  MaxWidth,
  minMedia,
  READ_WIDTH,
  theme
} from "../utils/style";
import { EasyGrid } from "./styles/EasyGrid";
import { HTMLProps } from "react";
import RTF from "./rtf";
import { useStaticQuery, graphql } from "gatsby";

interface Props {}

const Div = styled.div`
  display: flex;

  ${maxMedia.tablet`
    flex-direction: column;
    > .right {
      margin-top: 3rem;
    }
  `}

  ${minMedia.tablet`
    flex-direction: row;
    > *:not(:last-child) {
      margin-right: 5rem;
    }

    > .left {
      flex: 1 1 50%;
    }

    > .right {
      flex: 1 1 50%;
    }
  `}
`;

export const PresseKontakt = (props: Props) => {
  
  const data = useStaticQuery(graphql`
  {
    contentfulHtml(uid: { eq: "presse" }) {
      content {
        json
      }
    }
  }
`);

  return (
    <MaxWidth maxWidth={READ_WIDTH} id="presse">
      <h2 style={{ textAlign: "center", margin: "3rem" }}>Presse</h2>

      <Div>
        <div className="left">
          <h4>Pressekontakt</h4>

          <RTF content={data.contentfulHtml.content.json} />

        </div>
        <div className="right">
          <h4>Downloads</h4>

          <EasyGrid vertical>
          <DonwloadButton
              labelText="Vita Winfried Neun"
              text="Download"
              icon="download"
              download
              href={require("../assets/vita_winfried_neun.pdf")}
            />
            <DonwloadButton
              labelText="Portrait 1 W.Neun"
              text="Download"
              icon="download"
              download
              href={require("../images/presse/w_neun.jpg")}
            />
            <DonwloadButton
              labelText="Portrait 2 W.Neun"
              text="Download"
              icon="download"
              download
              href={require("../images/presse/w_neun_protrait.jpg")}
            />
            <DonwloadButton
              labelText="Stehend W.Neun"
              text="Download"
              icon="download"
              download
              href={require("../images/presse/w_neun__stehend_sw.jpg")}
            />
          </EasyGrid>
        </div>
      </Div>
    </MaxWidth>
  );
};

const iconLut = {
  download: require("../images/icon-download.svg"),
  play: require("../images/icon-play.svg"),
  share: require("../images/icon-share.svg"),
  subscribe: require("../images/icon-subscribe.svg"),
  book: require("../images/icon-book.svg")
};

interface DonwloadButtonProps extends HTMLProps<HTMLAnchorElement> {
  text?: string;
  labelText?: string;
  icon?: keyof typeof iconLut;
  component?: any;
}

export const DonwloadButton = styled(
  ({ text, labelText, icon, component, ...linkProps }: DonwloadButtonProps) => {
    const Component = component || ((props: any) => <a {...props} />);

    return (
      <Component {...linkProps}>
        {labelText && <span className="label">{labelText}</span>}
        <img
          className="icon"
          width={32}
          height={32}
          src={iconLut[icon || "download"]}
        />
        <CaptionText className="text">{text || "Download"}</CaptionText>
      </Component>
    );
  }
)`
  display: flex;
  align-items: center;
  cursor: pointer;

  > .label {
    margin-right: 2rem;
    width: 140px;
    color: ${theme.primaryColor};
  }

  > .icon {
    margin: 0;
    margin-right: 1rem;
  }

  > .text {
    white-space: pre;
  }
`;

export const CaptionText = styled.span`
  font-family: sans-serif;
  font-size: 0.85rem;
  color: #999999;
  letter-spacing: 3px;
  text-transform: uppercase;
`;
